@import "../../../../shared/styles/variables.scss";

.error {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.centered {
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.inner {
  display: flex;
  color: $darkred;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  .centered & {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: $darkblue;
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
  }
}

.icon {
  display: block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 4px;
  .centered {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    margin-right: 0;
  }
}

.networkError {
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.networkErrorInner {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: $darkblue;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}

.networkErrorIcon {
  display: block;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}
