@import "../../../../shared/styles/variables.scss";
@import "~sass-mq";

.tabBar {
  display: none;
  position: sticky;
  bottom: 0;
  justify-content: center;
  z-index: 10;

  box-shadow: 0 0 48px 0 rgba($darkblue, 0.075), 0 0 8px 0 rgba($darkblue, 0.075);

  background-color: $white;
  @include mq($until: desktop) {
    display: flex;
    flex-shrink: 0;
  }
}

.inner {
  display: flex;
  justify-content: center;
  height: $tab-bar-height;
  width: 100%;
  max-width: 720px;
}

.item {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
}
