@import "../../../../shared/styles/variables.scss";

.tabBarButton {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: $spacing-8;
  text-decoration: none;
}

.icon {
  display: block;
  height: $spacing-24;
  width: $spacing-24;
  margin-bottom: $spacing-2;

  color: $darkblue;
  svg {
    height: $spacing-24;
    width: $spacing-24;
  }
  .active & {
    color: $red;
  }
}
.label {
  color: $darkblue;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  white-space: nowrap;
  .active & {
    color: $red;
  }
}

.badge {
  position: absolute;
  top: 0px;
  right: 50%;
  background: $red;
  color: $white;
  width: 22px;
  height: 22px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  font-weight: 700;
  font-size: 12px;
  border: 2px solid $white;
}
